import moment from "moment"
import React, { useContext, useRef, useState } from "react"
import {
    MdAdd,
    MdEdit,
    MdInfo,
    MdRemoveCircle,
    MdSave,
    MdSearch,
    MdVideoLibrary,
} from "react-icons/md"
import Dialog from "../../../components/dialog"
import Inputfield from "../../../components/inputfield"
import MutableIcon from "../../../components/mutable-icon"
import {
    Column,
    Scroll,
    Item,
    Editor,
    EditorHeader,
    ListHeader,
    SaveButton,
    SearchContainer,
} from "./styles"
import { HiChevronLeft, HiOutlineDocument } from "react-icons/hi"
import { useNavigate } from "react-router-dom"
import AuthContext from "src/contexts/auth"
import { ExerciseTemplate } from "src/modules/fithub-shared/interfaces/templates"
import { Queries } from "src/assets/queries/exerciseTemplates/queries"
import {
    useDeleteExerciseMutation,
    useNewExerciseMutation,
    useEditExerciseMutation,
} from "src/assets/queries/exerciseTemplates/mutations"
import { FaYoutube } from "react-icons/fa"
import SideListView from "src/components/SideListView"
import { LineWave } from "react-loader-spinner"
import { CenteredDiv } from "src/screens/calendar/styling/styles"

// import { Container } from './styles';

export const FilterAndSortExc = (list: ExerciseTemplate[], search: string) => {
    return search.length > 0
        ? list
              .filter((e) =>
                  e.name.toUpperCase().includes(search.toUpperCase())
              )
              .sort((a, b) => {
                  const key = search.toLowerCase()
                  const isGoodMatchA = a.name.toLowerCase().startsWith(key)
                  const isGoodMatchB = b.name.toLowerCase().startsWith(key)

                  if (isGoodMatchA !== isGoodMatchB) {
                      // XOR
                      return isGoodMatchA ? -1 : 1
                  }

                  return a.name.localeCompare(b.name)
              })
              .slice(0, 50)
        : list
}

const ExerciseTemplates: React.FC = () => {
    const auth = useContext(AuthContext)

    const { data, isLoading } = Queries.useExercisesList(auth.user?.id)

    const [slice, setSlice] = useState(50)

    const [selected, setSelected] = useState<ExerciseTemplate | null>(null)

    const [name, setName] = useState("")
    const [media, setMedia] = useState("")
    const [obs, setObs] = useState("")

    const [remove, setRemove] = useState(false)

    const [filter, setFilter] = useState("")

    const navigate = useNavigate()

    // Agora você pode usar os hooks de mutação
    const deleteExerciseMutation = useDeleteExerciseMutation()
    const newExerciseMutation = useNewExerciseMutation()
    const editExerciseMutation = useEditExerciseMutation()

    const listaRef = useRef<HTMLUListElement>(null)

    const handleScroll: React.UIEventHandler<HTMLUListElement> = () => {
        const lista = listaRef.current
        if (!lista) return

        const { scrollTop, scrollHeight, clientHeight } = lista

        if (scrollTop + clientHeight + 50 >= scrollHeight) {
            if (!data) return

            const value = data.length - slice > 50 ? 50 : data.length - slice

            setSlice(slice + value)
        }
    }

    const handleDeleteExercise = async () => {
        await deleteExerciseMutation.mutateAsync(selected?.id)
        setSelected(null)
    }

    const handleNewExercise = async () => {
        setFilter("")
        await newExerciseMutation.mutateAsync("Novo Template")
    }

    const handleSaveEdit = async () => {
        if (selected)
            await editExerciseMutation.mutateAsync({
                id: selected.id,
                name: name,
                media_url: media,
                obs: obs,
            } as ExerciseTemplate)
    }

    function Search(t: string) {
        if (slice !== 50) setSlice(50)
        setFilter(t)
    }

    function Select(e: ExerciseTemplate) {
        if (selected === null || selected != e) {
            setSelected(e)
            setName(e.name)
            setMedia(e.media_url)
            setObs(e.obs)
        } else setSelected(null)
    }

    function Redirect(link: string) {
        if (link.length < 4) return

        if (link.includes("http://") || link.includes("https://"))
            window.open(link, "_blank")
        else window.open("http://" + link, "_blank")
    }

    const ExcEditor = () => {
        if (selected === null) {
            return (
                <Column>
                    <Editor style={{ position: "relative" }}>
                        <h3 style={{ fontSize: 18, fontWeight: "bold" }}>
                            Selecione ou Crie um novo exercício...
                        </h3>
                    </Editor>
                </Column>
            )
        } else {
            return (
                <div
                    className="centered"
                    style={{ display: `flex`, height: 600 }}
                >
                    <Editor
                        style={{
                            cursor:
                                selected.created_by == null
                                    ? "not-allowed"
                                    : undefined,
                        }}
                    >
                        <EditorHeader>
                            <div style={{ flexDirection: "column" }}>
                                <h4 style={{ color: "white" }}>{name}</h4>
                                <p>
                                    Criado em{" "}
                                    {moment(selected.createdat).format(
                                        "DD/MM/yy"
                                    )}
                                </p>
                            </div>
                            <div onMouseEnter={(e) => e.currentTarget.focus()}>
                                <MutableIcon
                                    color="white"
                                    size={28}
                                    icon={FaYoutube}
                                    onClick={() => Redirect(media)}
                                />
                            </div>
                        </EditorHeader>

                        <div className="flex column" style={{ width: "100%" }}>
                            <Inputfield
                                disabled={selected.created_by == null}
                                width={"100%"}
                                placeholder="Nome"
                                icon={MdEdit}
                                defaultValue={name}
                                onEndText={(e) => setName(e)}
                                maxLength={128}
                                style={{
                                    opacity:
                                        selected.created_by == null ? 0.5 : 1,
                                }}
                            />
                            <span />
                            <Inputfield
                                disabled={selected.created_by == null}
                                width={"100%"}
                                placeholder="Mídia"
                                icon={MdVideoLibrary}
                                defaultValue={media}
                                onEndText={(e) => setMedia(e)}
                                style={{
                                    opacity:
                                        selected.created_by == null ? 0.5 : 1,
                                }}
                            />
                            <span />
                            <Inputfield
                                disabled={selected.created_by == null}
                                width={"100%"}
                                placeholder="Observações"
                                icon={MdInfo}
                                defaultValue={obs}
                                onEndText={(e) => setObs(e)}
                            />
                        </div>

                        <div>
                            <SaveButton
                                disabled={selected.created_by == null}
                                onClick={() => setRemove(true)}
                            >
                                <MutableIcon icon={MdRemoveCircle} />{" "}
                                <p>Excluir</p>
                            </SaveButton>
                            <span />
                            <SaveButton
                                disabled={selected.created_by == null}
                                onClick={handleSaveEdit}
                            >
                                <MutableIcon icon={MdSave} />
                                <p>Salvar Alterações</p>
                            </SaveButton>
                        </div>
                    </Editor>
                </div>
            )
        }
    }

    if (!data || isLoading) {
        return (
            <CenteredDiv>
                <LineWave color="black" width={60} height={60} />
            </CenteredDiv>
        )
    } else {
        console.log("Loaded", data.length, auth.user?.id)
    }

    const filtered = FilterAndSortExc(data, filter).slice(0, slice)

    return (
        <div
            className="centered"
            style={{
                display: "flex",
                width: "100%",
            }}
        >
            <SideListView
                style={{ maxWidth: 1980 }}
                list={
                    <Column>
                        <ListHeader>
                            <MutableIcon
                                size={32}
                                icon={HiChevronLeft}
                                onClick={() => navigate(-1)}
                            />
                            <SearchContainer>
                                <h3 style={{ fontWeight: "bold" }}>
                                    Lista de Exercícios
                                </h3>
                                <span />
                                <Inputfield
                                    onChangeText={Search}
                                    onEndText={setFilter}
                                    icon={MdSearch}
                                    placeholder="Pesquisar..."
                                    value={filter}
                                    width={"100%"}
                                />
                            </SearchContainer>
                        </ListHeader>
                        <Scroll ref={listaRef} onScroll={handleScroll}>
                            <Item
                                selected={false}
                                onClick={handleNewExercise}
                                style={{
                                    fontWeight: "bold",
                                    color: "white",
                                    backgroundColor: "#888",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <MutableIcon icon={MdAdd} size={24} />
                                <p
                                    style={{
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 14,
                                    }}
                                >
                                    CRIAR EXERCÍCIO TEMPLATE
                                </p>
                            </Item>
                            {filtered.map((element, index) => {
                                return (
                                    <Item
                                        key={index}
                                        selected={selected?.id === element.id}
                                        style={{ fontWeight: "" }}
                                        onClick={() => Select(element)}
                                    >
                                        <div style={{ alignItems: "center" }}>
                                            <HiOutlineDocument color="#777" />
                                            <p style={{ paddingLeft: 5 }}>
                                                {element.name}
                                            </p>
                                        </div>
                                        <h4>
                                            {!element.created_by && "FITHUB"}
                                        </h4>
                                    </Item>
                                )
                            })}
                        </Scroll>
                    </Column>
                }
                content={<ExcEditor />}
                onGoBack={() => setSelected(null)}
                view={selected ? "CONTENT" : "LIST"}
            />
            {remove && (
                <Dialog
                    active={remove}
                    title={"Excluir Template"}
                    subtitle={
                        "Tem certeza que você quer excluir esse template?\n\nEssa ação irá remover este exercício de todos os treinos existentes."
                    }
                    state={setRemove}
                    onConfirm={handleDeleteExercise}
                />
            )}
        </div>
    )
}

export default ExerciseTemplates
