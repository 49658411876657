import React from "react"
import ActivityItem from "../ActivityItem"
import { MdNotifications } from "react-icons/md"
import { TbActivityHeartbeat } from "react-icons/tb"
import { Activity } from "src/modules/fithub-shared/notifications/index.types"

// import { Container } from './styles';

const Activities: React.FC<{ activities: Activity[] }> = ({ activities }) => {
    return (
        <ul>
            {activities.length > 0 ? (
                activities.map((e, i) => (
                    <ActivityItem key={`${i}_Act`} activity={e} />
                ))
            ) : (
                <div
                    style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            flexDirection: "column",
                            alignItems: "center",
                            color: "#888",
                        }}
                    >
                        <TbActivityHeartbeat size={32} />
                        <p>Não há notificações ativas!</p>
                    </div>
                </div>
            )}
        </ul>
    )
}

export default Activities
