import React from "react"
import { NotificationData } from "src/modules/fithub-shared/notifications/index.types"
import { Paragraph } from ".."
import dummy from "src/assets/png/userd-demo-pic.png"
import { HiChevronRight } from "react-icons/hi"
import { useStudent } from "src/contexts/student"
import styled from "styled-components"
import moment from "moment"
// import { Container } from './styles';

const Title = styled.p`
    height: 100%;

    font-size: 12px;
    line-height: 1.2;

    color: #777;

    strong {
        color: black;
        vertical-align: baseline; /* Alinhar o strong à linha de base */
        padding-right: 4px;
    }
`

const NotFeedback: React.FC<{ e: NotificationData }> = ({ e }) => {
    const student = useStudent()

    function loadStudent() {
        student.onSetStudent(e.id)
    }

    return (
        <>
            <div style={{ flex: 1 }} onClick={loadStudent}>
                <img
                    style={{
                        height: 30,
                        width: 30,
                        outline: "none",
                        borderRadius: "50%",
                        marginRight: 10,
                    }}
                    src={e.photourl ?? dummy}
                />
                <div
                    style={{
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Title>
                        <strong>{e.name}</strong>
                        {e.subtitle.replace("# ", "")}
                    </Title>
                    <Paragraph>{moment(e.date).format("LL")}</Paragraph>
                </div>
            </div>

            <HiChevronRight />
        </>
    )
}

export default NotFeedback
