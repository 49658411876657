import React, { useEffect, useState } from "react"
import { MdChevronRight } from "react-icons/md"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { Activity } from "src/modules/fithub-shared/notifications/index.types"

// import { Container } from './styles';

export const Item = styled.button`
    padding: 10px;

    flex-shrink: 0;
    height: 60px;
    width: 100%;

    border: 1px solid var(--panel);

    border-radius: 2px;

    align-items: center;

    justify-content: space-between;

    text-align: start;

    > div {
        flex-direction: column;

        > h4 {
            font-weight: 600;
            font-size: 14px;
        }
    }

    > span {
        flex-shrink: 0;
        width: 95px;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        background-color: #0002;
        border-radius: 25px;

        > h6 {
            padding-left: 5px;
            font-weight: inherit;
            text-transform: uppercase;
        }
    }

    :active {
        opacity: 0.5;
    }

    :not(:last-child) {
        border-bottom: 1px solid #0002;
    }

    cursor: pointer;
`

const ActivityItem: React.FC<{ activity: Activity }> = ({ activity }) => {
    const nav = useNavigate()

    const [data, setData] = useState<{
        fun: (() => void) | null
        message: string
    }>({ fun: null, message: "" })

    useEffect(() => {
        Action()
    }, [])

    function Action() {
        switch (activity.type) {
            default:
                break
            case "FEEDBACK":
                setData({
                    fun: () =>
                        nav(
                            `/student/progression?plan=${activity.data.plan}&exc=${activity.data.exercise}`
                        ),
                    message: activity.data.feedback,
                })

                break
        }
    }

    function cta() {
        return data.fun ? data.fun() : console.log("no action")
    }

    return (
        <Item onClick={cta}>
            <div>
                <h4>{activity.title}</h4>
                <h5>{data.message}</h5>
            </div>
            <span>
                <h6>Ver Progressão</h6>
                <MdChevronRight />
            </span>
        </Item>
    )
}

export default ActivityItem
