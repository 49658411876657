import React, { useEffect, useRef, useState } from "react"
import {
    CommentField,
    Container,
    DragItem,
    Header,
    HeaderButton,
    HeaderText,
    HeaderTextScroll,
    HeaderTitle,
    RowAligned,
} from "./styles"
//import EditableDropdown from "../../../../components/editable-dropdown";
import {
    ExerciseFields,
    ExerciseValue,
    WeightExercise,
} from "../../../../../../fithub-shared/interfaces/exercise"

import {
    MdAddCircle,
    MdCheck,
    MdDelete,
    MdEdit,
    MdRemove,
} from "react-icons/md"
import {
    TbArrowsUpDown,
    TbMenu as TbMenuOrder,
    TbZoomReplace,
} from "react-icons/tb"

import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { useOnOutClick } from "../../../../../../../components/hooks"
import api from "src/API/rest"
import mostrarAlerta from "src/components/utils/alert"
import { ExerciseExists } from "src/screens/templates/cache"
import Column2 from "../Column2"
import { isMobile } from "react-device-detect"
import { PiYoutubeLogoFill } from "react-icons/pi"
import { useVideoOverlay } from "src/components/VideoOverlay/useVideoOverlay"
import VideoOverlay from "src/components/VideoOverlay"
import convertYouTubeLinkToEmbed from "src/modules/fithub-shared/functions/youtube-utils"
// import { Container } from './styles';

interface ExerciseProps {
    index: number
    id: string
    onChange: (d: WeightExercise) => void
    onReplace: (d: WeightExercise) => void
    onDelete: () => void
    def: WeightExercise
}

const WeightExerciseComponent: React.FC<ExerciseProps> = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
        isSorting,
    } = useSortable({
        id: props.id,
    })

    const [def, setDef] = useState<WeightExercise>(props.def)
    const [exists, setExists] = useState<string | null>(def.name)

    useEffect(() => {
        CheckExists()
    }, [])

    function CheckExists() {
        ExerciseExists(def.linkId).then((res) => {
            setExists(res)
        })
    }

    const ref = useRef<HTMLDivElement>(null)
    const [obsPanel, setObsPanel] = useState(false)
    const [obs, setObs] = useState(def.obs)

    useOnOutClick(ref, HandleObsSave)

    const style = {
        transform: CSS.Transform.toString(transform),
        zIndex: isDragging ? 10 : undefined,
        transition,
    }

    function handleChange(w: ExerciseValue, field: ExerciseFields) {
        const _def = { ...def }
        _def.values[field] = w
        setDef(_def)
        props.onChange(_def)
    }

    function handleObsChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setObs(e.currentTarget.value)
    }

    function removeObs() {
        const _def = { ...def }
        _def.obs = ""
        setDef(_def)
        props.onChange(_def)
        setObsPanel(false)
    }

    function HandleObsSave() {
        const _def = { ...def }
        _def.obs = obs
        setDef(_def)
        props.onChange(_def)
        setObsPanel(false)
    }

    function Redirect() {
        api.post("/exercises/get-video", { id: def.linkId })
            .then((res) => {
                if (res.data.length < 4) {
                    mostrarAlerta(
                        "Este exercício não possui um video vinculado!"
                    )
                    return
                }
                openVideo(convertYouTubeLinkToEmbed(res.data))
            })
            .catch((err) => {
                mostrarAlerta(err)
            })
    }

    const { isVideoOpen, videoUrl, openVideo, closeVideo } = useVideoOverlay()

    ;("")

    return (
        <Container style={style}>
            {isVideoOpen && videoUrl && (
                <>
                    <VideoOverlay videoUrl={videoUrl} onClose={closeVideo} />
                </>
            )}
            {obsPanel && (
                <CommentField className="flex column" ref={ref}>
                    <textarea
                        defaultValue={def.obs}
                        onChange={handleObsChange}
                    />
                    <div className="buttons">
                        <button className="delete" onClick={HandleObsSave}>
                            <MdCheck />
                            <p>Concluir</p>
                        </button>
                        <button className="" onClick={removeObs}>
                            <MdRemove />
                            <p>Remover</p>
                        </button>
                    </div>
                </CommentField>
            )}

            <Header {...attributes} tabIndex={-1}>
                <div
                    style={{
                        alignItems: "center",
                    }}
                >
                    <DragItem
                        ref={setNodeRef}
                        {...listeners}
                        isDragging={isDragging}
                    >
                        {isDragging ? (
                            <TbArrowsUpDown size={14} />
                        ) : (
                            <TbMenuOrder size={16} />
                        )}
                    </DragItem>
                </div>
                <HeaderButton
                    tabIndex={-1}
                    onClick={() => props.onReplace(def)}
                >
                    <TbZoomReplace size={14} />
                </HeaderButton>
                <HeaderTitle>
                    <div
                        style={{ width: "fit-content", alignItems: "center" }}
                    ></div>
                    <HeaderTextScroll>
                        {exists ? (
                            <HeaderText>{exists}</HeaderText>
                        ) : (
                            <HeaderText style={{ color: "red" }}>
                                {def.name + " [EXCLUÍDO]"}
                            </HeaderText>
                        )}
                    </HeaderTextScroll>
                </HeaderTitle>
                <HeaderButton
                    tabIndex={-1}
                    onClick={() => setObsPanel(true)}
                    width={isMobile ? 80 : 120}
                    style={{ color: "white", backgroundColor: "#777" }}
                >
                    <div
                        className="flex row"
                        style={{ paddingInline: 5, alignItems: "center" }}
                    >
                        {def.obs ? (
                            <MdEdit size={18} />
                        ) : (
                            <MdAddCircle size={18} />
                        )}
                        <div style={{ margin: 2 }} />
                        {def.obs ? "Editar" : "Add"} Obs.
                    </div>
                </HeaderButton>
                <HeaderButton tabIndex={-1} onClick={Redirect}>
                    <PiYoutubeLogoFill color={"#222"} size={20} />
                </HeaderButton>
                <HeaderButton tabIndex={-1} onClick={props.onDelete}>
                    <MdDelete color={"#222"} size={20} />
                </HeaderButton>
            </Header>

            <RowAligned className="sheet">
                <Column2
                    name="series"
                    isDragging={isDragging || isSorting}
                    default={def.values.series}
                    onChange={handleChange}
                    maxNums={1}
                />
                <div style={{ padding: 1 }} />
                <Column2
                    name="reps"
                    isDragging={isDragging || isSorting}
                    default={def.values.reps}
                    onChange={handleChange}
                    series={def.values.series.value[0]}
                    maxNums={2}
                />

                <div style={{ padding: 1 }} />
                <Column2
                    name="cadency"
                    isDragging={isDragging || isSorting}
                    default={def.values.cadency}
                    onChange={handleChange}
                    maxNums={1}
                />
                <div style={{ padding: 1 }} />
                <Column2
                    name="interval"
                    isDragging={isDragging || isSorting}
                    default={def.values.interval}
                    onChange={handleChange}
                    maxNums={3}
                />
                <div style={{ padding: 1 }} />
                <Column2
                    name="method"
                    isDragging={isDragging || isSorting}
                    default={def.values.method}
                    onChange={handleChange}
                    maxNums={0}
                    enableSelectTab
                />
            </RowAligned>
        </Container>
    )
}

export default WeightExerciseComponent
