import React, { useContext, useEffect, useState } from "react"

import {
    AnamneseSettingsBackground,
    GoBackButton,
    ItemLabel,
    OptionsPanel,
    SelectableItem,
} from "./styles"

import {
    MdLink,
    MdRadioButtonChecked,
    MdRadioButtonUnchecked,
    MdSave,
} from "react-icons/md"
import Inputfield from "src/components/inputfield"
import { BiChevronLeft } from "react-icons/bi"
import { useNavigate } from "react-router-dom"
import AuthContext from "src/contexts/auth"

import {
    useGetAnamnese,
    useAnamneseMutation,
} from "src/assets/queries/anamnese/queries"
import { CenteredDiv } from "../calendar/styling/styles"
import { LineWave } from "react-loader-spinner"

// import { Container } from './styles';

const Anamnese: React.FC = () => {
    const [selected, setSelected] = useState(0)

    const auth = useContext(AuthContext)
    const navigate = useNavigate()
    const { data, isLoading } = useGetAnamnese(auth.user?.id)
    const anamneseMutation = useAnamneseMutation()

    useEffect(() => {
        if (data) setSelected(data.active)
    }, [])

    function handleChangeUrl(e: string) {
        if (data) data.external_url = e
    }

    async function Save() {
        if (data)
            await anamneseMutation.mutateAsync({
                id: data.id,
                content: data.content,
                external_url: data.external_url,
                active: selected,
            })
    }

    const RadioButton = (p: { checked: boolean }) =>
        p.checked ? (
            <MdRadioButtonChecked size={20} />
        ) : (
            <MdRadioButtonUnchecked size={20} />
        )

    if (isLoading)
        return (
            <CenteredDiv>
                <LineWave color="black" width={80} height={80} />
            </CenteredDiv>
        )
    else {
        return (
            <AnamneseSettingsBackground className="fade-in flex row">
                <OptionsPanel>
                    <SelectableItem className="shadow">
                        <ItemLabel onClick={() => setSelected(0)}>
                            <RadioButton checked={selected === 0} />
                            <h4>Desativar Anamnese </h4>
                        </ItemLabel>
                    </SelectableItem>
                    <SelectableItem className="shadow">
                        <ItemLabel onClick={() => setSelected(1)}>
                            <RadioButton checked={selected === 1} />
                            <h4>Link Externo</h4>
                        </ItemLabel>
                        <span />
                        <Inputfield
                            defaultValue={data?.external_url}
                            placeholder="https://docs.google.com/forms..."
                            onChangeText={handleChangeUrl}
                            icon={MdLink}
                            height={40}
                        />
                    </SelectableItem>

                    <SelectableItem className="shadow disabled">
                        <ItemLabel>
                            <RadioButton checked={selected === 2} />
                            <h4>Fithub Forms</h4>
                        </ItemLabel>
                        <span />
                        <div>
                            <p>Você ainda não criou um formulário!</p>
                        </div>
                    </SelectableItem>
                    <div style={{ flex: 1, flexDirection: "row" }}>
                        <GoBackButton onClick={() => navigate(-1)}>
                            <BiChevronLeft size={28} />
                            <p>Voltar</p>
                        </GoBackButton>
                        <span style={{ margin: 5 }} />
                        <GoBackButton onClick={Save}>
                            <MdSave size={24} />
                            <p>Salvar</p>
                        </GoBackButton>
                    </div>
                </OptionsPanel>
            </AnamneseSettingsBackground>
        )
    }
}

export default Anamnese
